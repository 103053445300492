body.menu-open {
  overflow: hidden;
  height: 100vh;
}



.fullscreen-menu {
  z-index: 1;
  background-image: linear-gradient(120deg, #000 0%, #000 100%);
  overflow: visible!important;
}

.fullscreen-menu.closed {
  background: none!important;
}

.fullscreen-menu hr {
  max-width: 400px;
  margin: auto;
}

.fullscreen-menu .animatesTriangleLeft {
  position: fixed;
  left: -30px;
  bottom: -20vh;
  z-index: 0;
  width: 400px;
  height: 400px;
  overflow: visible;
}

.fullscreen-menu .animatesTriangleLeft img {
}

.fullscreen-menu .animatesTriangleRight img {
  width: 1000px;
  height: 1000px;
}

.fullscreen-menu .animatesTriangleRight {
  position: fixed;
  right: -400px;
  top: -170px;
  z-index: 0;
}

.fullscreen-menu .logo-bar {
  padding-top: 100px;
  padding-bottom: 0px;
  position: relative;
  z-index: 1;

}

.fullscreen-menu .logo-bar::before {
  content: '';
  width: 200px;
  height: 200px;
  background-image: url(../images/menuCenterPolygon.svg);
  background-size: cover;
  display: block;margin: auto;
  position: absolute;
  top: -120px;
  left: 50%;
  margin-left: -100px;
}

.fullscreen-menu .logo-bar img {
  max-width: 200px;
}

.fullscreen-menu .headline-bar {
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.fullscreen-menu .headline-bar {
  font-weight: 800;
  font-size: 7.526rem;
  color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-family: futura-pt, sans-serif;
}

.fullscreen-menu hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.fullscreen-menu .col-ticket {
  font-size: 2.225rem;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  text-align: center;

}

.fullscreen-menu .col-ticket a,
.fullscreen-menu .col-ticket button {
  text-decoration: none!important;
  font-size: 2.525rem;
  font-weight: 700;
  letter-spacing: 0.225rem;
  color: #fff;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
}

.fullscreen-menu .col-ticket:hover a,
.fullscreen-menu .col-ticket:hover button {
  color: #000!important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.fullscreen-menu .col-ticket svg {
  color: #d90000;
}

.fullscreen-menu  .year-bar {
  font-size: 13.226rem;
  line-height: 11.226rem;
  letter-spacing: 1.525rem;
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #565656;
  color: transparent;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
}

.fullscreen-menu .ticket-icon {
  font-size: 7.525rem;
  line-height: 5rem;

}


.fullscreen-menu .info-grid {
  border: 2px #fff solid;
  padding: 0px;
  height: 161px;
}

.fullscreen-menu .info-grid .mail {
  border-bottom: 2px #fff solid;
  font-size: 1.325rem;
  text-align: center;
  font-weight: 700;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  padding: 10px;
}




.fullscreen-menu .menu-bar .row>div {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
align-self: flex-start;
align-content: flex-start;
}

.fullscreen-menu .menu-bar {
  padding-bottom: 0px;
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: auto;
  text-align: left;
  margin-top: 100px;
}

.fullscreen-menu .menu-bar li {
  color: #fff;
  line-height: 3.325rem;
  letter-spacing: 0.425rem;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: #fff;
  transition: all .5s ease;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.fullscreen-menu .menu-bar li a {
  font-size: 2.625rem;
  color: #fff;
  letter-spacing: 0.425rem;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: #fff;
  transition: all .5s ease;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.fullscreen-menu .menu-bar li:hover a {
  color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.fullscreen-menu .social-bar {
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
}

.fullscreen-menu .social-bar ul {
  margin-top: -1px!important;
  width: 100%;
  position: relative;
  right: 0px;
  left: 0px;
}

body .fullscreen-menu .row>div {
  flex-wrap: wrap;
  justify-content: flex-start!important;
  align-items: flex-start!important;
  align-self: flex-start!important;
  align-content: flex-start!important;
}

.fullscreen-menu .social-bar li {
  border: 1px #fff solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 61px;
  height: 61px;
  transition: all 0.2s var(--circ-in-out);
}

.fullscreen-menu .social-bar li:last-child {
  border-right: 2px #fff solid;
}

.fullscreen-menu .social-bar li:first-child {
  border-left: 0px;
}

.fullscreen-menu .social-bar li:hover {
  background-color: #fff;
  color: #000;
}

.fullscreen-menu .social-bar li:hover a {
  color: #000;
}

.fullscreen-menu .social-bar li a {
  transition: all 0.5s ease-in;
  font-size: 1.625rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}

.fullscreen-menu .social-bar a {
  color: #fff;
}


.fullscreen-menu>div,
.fullscreen-menu>div>div {
  height: 100%;
  z-index: 0!important;
}

.fullscreen-menu .social-bar h2 {
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125rem;
  font-size: 1.225rem;
  margin-bottom: 0px;
  padding: 10px;
  border-bottom: 2px #fff solid;
  border-right: 2px #fff solid;
}

.fullscreen-menu .close-btn {
  position: fixed;
    left: 50%;
    color: #fff!important;
    background-color: transparent!important;
    width: 60px;
    height: 60px;
    padding: 0px;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    /* display: block; */
    border-radius: 100%;
    transform: translateX(-30px);
    top: 90%;
}

.fullscreen-menu .close-btn svg {
  fill: #fff;
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
}


.fullscreen-menu .video-bar {
  max-width: 1000px;
  margin: auto;
  margin-top: 30px;
  display: none;
}

.fullscreen-menu .open-btn {
  background: none!important;
  color: #fff !important;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 1.225rem !important;
  float: right;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
}

.fullscreen-menu .open-btn svg {
  fill: #d90000;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  color: #d90000;
  margin-right: 15px;
}




.fullscreen-menu .terms-bar  {
  position: relative;
  z-index: 1;
  padding-top: 0px;
  margin-top: 17px;
  left: 22%;
}

.fullscreen-menu .terms-bar ul {
  width: 100%;
}

.fullscreen-menu .terms-bar li {
  font-size: 0.925rem;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  line-height: normal;
}

.fullscreen-menu .terms-bar li a {
  color: #fff!important;
  text-decoration: none;
  font-size: 1.125rem;
  letter-spacing: 0.225rem;
}

.fullscreen-menu .terms-bar li {
  -webkit-text-stroke-width: 0px!important;
}

.fullscreen-menu .terms-bar li:hover a {
  -webkit-text-stroke-width: 0px!important;
  -webkit-text-stroke-color: #fff;
  color: #e20917!important;
}







/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
  .fullscreen-menu .animatesTriangleLeft {
    display: none;
  }
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .fullscreen-menu .animatesTriangleRight {
    display: none;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .fullscreen-menu .year-bar {
    top: 50px;
  }

  .fullscreen-menu .terms-bar li a {
    font-size: 1.025rem;
    padding-left: 10px;
  }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

  .fullscreen-menu>div:nth-child(2)>div {
    max-height: 85vh!important;
  }


  .fullscreen-menu .close-btn {
    margin-top: -20px;
  }

  .fullscreen-menu .col-ticket a, .fullscreen-menu .col-ticket button {
    font-size: 1.725rem;
  }

    .fullscreen-menu .menu-bar li {
      width: 50%;
      padding-left: 10px;
    }


    .fullscreen-menu .menu-bar li:first-child {
      text-align: right;
      padding-right: 10px;
    }
    .fullscreen-menu .menu-bar li:nth-child(2n+3) {
      text-align: right;
      padding-right: 10px;
    }

    .fullscreen-menu .menu-bar li {
      line-height: normal;
    }


  .fullscreen-menu .menu-bar li a {
    font-size: 2.025rem;
    letter-spacing: 0.0525rem;;
    line-height: normal;
  }



  .fullscreen-menu .menu-bar {
    margin-top: 20px;
  }

  .fullscreen-menu .menu-bar  ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    top: 30px;
  }

  #top-bar .row>div {
    display: block;
  }


  .fullscreen-menu .year-bar {
    font-size: 5.525rem;
    line-height: 5.525rem;
  }

  .fullscreen-menu>div, .fullscreen-menu>div>div {
    overflow: scroll!important;
  }

  .fullscreen-menu .terms-bar ul {
    display: flex;
    flex-wrap: nowrap;
  }

  .fullscreen-menu .logo-bar {
    padding-top: 20px;
  }

  .fullscreen-menu .logo-bar::before {
    display: none;
  }

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .fullscreen-menu>div:nth-child(2)>div {
    max-height: 80vh!important;
  }
}
