#lineup {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#lineup .body {
  padding-bottom: 50px;
  max-width: 900px;

  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}


#lineup h2 {
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 3.925rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
  margin-bottom: 80px;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d90000;
}


#lineup .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#lineup  img:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


#lineup .artist-bar  {
  padding-bottom: 100px;
}

#lineup .artist-bar img {
  max-width: none;
  max-width: 100%;
  width: auto;
  margin-bottom: 20px;
  max-height: 306px;
}

#lineup .artist-bar a {
  text-decoration: none;
  color: inherit;
}

#lineup .artist-bar h3 {
  margin-bottom: 30px;
}

#lineup .artist-bar h3::after {
  content: '';
  display: block;
  height: 4px;
  background-color: #d90000;
  width: 70px;
  margin: auto;
  margin-top: 8px;
  display: none;
}



/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  #lineup h2 {
    font-size: 3.025rem;
    margin-bottom: 20px;
  }
}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  #lineup .body {
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
    
  }

}
