#artist {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}



#artist h2 {
  color: #000;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 8.125rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}

#artist .overlay {
  display: none;
}


.artist-headline {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  text-transform: uppercase;
color: #e8e8e8;
font-size: 25.225rem;
line-height: 20rem;
line-height: 20rem;
white-space: nowrap;
font-weight: 700;
overflow: hidden;
}

#artist::before {
  content: '';
  width: 100%;
  height: 600px;
  background-size: contain;
  display: block;
  position: absolute;
  left: 0x;
  right: 0px;
  z-index: 0;
  top: 200px;
}

#artist .image-bar {
  overflow: hidden;
  position: relative;
}

.artist-headline {
  z-index: -1;
}


#artist hr {
  display: none;
}

#artist .image-bar h6 {
}


#artist .image-bar h6 a {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.325rem;
  margin-bottom: 0px;
  color: transparent;
  font-weight: 800;
  font-size: 3.926rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  padding-left: 7px;
  padding-right: 7px;
  text-decoration: none;
  white-space: nowrap;
}

#artist .image-bar img {
  max-height: 400px;
  width: auto;
  height: auto;
  max-width: 100%;
}

#artist  .artist-details {
  position: relative;
  z-index: 0;
  padding-bottom: 80px;
}


#artist  .social-bar {
  margin-top: 30px;
}


#artist  .social-bar h3 {
  margin-bottom: 30px;
  text-transform: uppercase;
}

#artist  .social-bar li {
  border: 1px #000 solid;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 -1px -1px 0;
  background: #fff;
  transition: all 0.2s var(--circ-in-out);
}

#artist  .social-bar li:hover {
  background-color: #000;
  color: #fff;
}

#artist  .social-bar li:hover a {
  color: #fff;
}

#artist .social-bar li a {
  transition: all 0.5s ease-in;
  font-size: 1.425rem;
  color: #000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}



@media (max-width: 767.98px) {
  #artist h2{
    font-size: 4.125rem;
  }

}
