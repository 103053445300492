#history {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#history .container {
  max-width: 650px;
}

#history .body {
  padding-bottom: 100px;
  max-width: 800px;
  text-align: center;
  margin: auto;
}

#history .row>div>button {
  border: none;
  padding: 0px;
  border: none;
  background: none;
  outline: none;
  box-shadow: none!important;
  padding-left: 20px;
  padding-right: 20px;
}


#history h3 {
  color: #d90000;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
  margin-bottom: 50px;
}

#history  iframe {
  margin-bottom: 150px;
}
