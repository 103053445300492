#gallery {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#gallery .container {
  max-width: 950px;
}

#gallery .row {
  flex-wrap: wrap;
}

#gallery .ant-list-item {
    max-height: 140px;
    overflow: hidden;
    margin: 0px;
}

#gallery .ant-row {
  padding-left: 10px;
  padding-right: 10px;
}

#gallery .ant-col {
  padding: 10px!important;
}


#gallery .headline::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d90000;
  margin-top: -15px;
}

#gallery .headline {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

#gallery .headline h2 {
  display: inline;;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  font-weight: 600;
  font-size: 1.225rem;
}

#gallery #gallery-tabs {
  border: none;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

#gallery #gallery-tabs li>button {
  border: none!important;
  margin: 0px!important;
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 0.925rem;
  border-radius: 0px;
  border: 2px #000 solid!important;
}

#gallery #gallery-tabs li {
  border-radius: 0px;
  border: none;
  margin: 10px;
}

#gallery #gallery-tabs li>button.active {
  background-color: #e20a17;
  color: #fff!important;
  border: 2px #e20a17 solid!important;
}

#gallery-tab-content {
  padding-bottom: 50px;
}

#gallery-tab-content button {
  border: none;
  padding: 0px;
}






/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  #gallery .ant-list-item {
      max-height: 110px;
      overflow: hidden;
  }
}
