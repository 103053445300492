#lounges {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#lounges .btn-tour {
  border: none;
  border-radius: 0px;
  background-color: #d90000;
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 1.325rem;
  font-weight: 700;
  padding: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 0.125rem;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

#lounges .body {
  padding-bottom: 50px;
  max-width: 1100px;
  margin: auto;
  text-align: center;

  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}

#lounges .lounges-headline {
  overflow: hidden!important;
}

#lounges .lounges-headline .overlay {
  display: none;
}

#lounges .lounges-headline .item {
  padding-left: 40px;
  padding-right: 40px;
}

.lounges-plan-bar button img {
  max-width: 500px;
  margin-bottom: 50px;
}

#lounges .lounges-headline {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 50%;
  margin-top: -180px;
  z-index: 0;

  text-transform: uppercase;
  color: #e8e8e8;
  opacity: 0.5;
  font-size: 25.225rem;
  line-height: 20rem;
  white-space: nowrap;
  font-weight: 700;
  overflow: hidden;
  opacity: 0.3;
}

#lounges  .col-md-12 {
  position: relative;
}

#lounges  .col-md-12 .container {
  z-index: 0;
  position: relative;
}


#lounges .container-list {
  padding-bottom: 100px;
  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}

#lounges .container-list>div {
  padding: 0px;
}

#lounges .container-list h3 {
  width: 100%;
  font-weight: 700;
  border-bottom: 2px #e81600 solid;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

#lounges .container-list .container .row {
  padding-bottom: 50px;
}

#lounges .container-list .container .row>div {

}

#lounges .container-list>.row.even .container .row>div:nth-child(1) {
  order: 1;
}

#lounges .container-list>.row.even .container .row>div:nth-child(2) {
  order: 0;
  text-align: right;
}

#lounges .container-list>.row.even .lounges-headline {
  left: 50%;
  right: 0%;
}

#lounges .container-list img {
  width: 100%;
  max-width: none;
}

#lounges .lounges-headline  h1 {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.325rem;
  margin-bottom: 0px;
  color: transparent;
  font-weight: 800;
  font-size: 3.926rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d90000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  padding-left: 7px;
  padding-right: 7px;
  text-decoration: none;
  background-color: #FFF;
}

#lounges .lounges-headline  h1.fill {
  color: #d90000;
}

#lounges h2 {
  background-color: #d90000;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#lounges .container-list {
  text-align: left;
  position: relative;
}


.lounges-gplan-bar img {
  max-width: 700px;
  margin: auto;
  width: 100%;
}




/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

  #lounges .container-list .container .row {
    padding-bottom: 20px;
  }

  #lounges .container-list {
    padding-bottom: 20px;
  }

  #lounges .container-list h3 {
    margin-top: 20px;
  }

  #lounges .col-md-12 .container {
    padding: 0px;
  }

  #lounges .lounges-headline {
    display: none;
  }

  #lounges .container-list {
    text-align: center;
  }

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  #lounges .body {
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
  }

  #lounges .container-list {
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
  }

  #lounges .btn-tour {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }

}
