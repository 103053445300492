#tickets {
  padding-top: 50px;
  width: 100%;
}

#tickets .body {
  padding-bottom: 100px;
  max-width: 800px;

  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}

#tickets .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}





#tickets .ticket-grid h2 {
  color: #d90000;
  font-size: 1.725rem;
  font-weight: 700;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

#tickets .ticket-grid .btn {
  border: none;
  border-radius: 0px;
  background-color: #d90000;
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 1.325rem;
  font-weight: 700;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 0.125rem;
}

#tickets .ticket-grid .price {
  font-weight: 700;
  font-size: 1.625rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: proxima-nova, sans-serif;
}

#tickets .ticket-grid .btn svg {
  margin-right: 10px;
}

#tickets .ticket-grid .body {
  padding: 0px;
}

#tickets .ticket-grid .call-to-action {
  margin-bottom: 80px;
}

#tickets .ticket-grid .row>div:nth-child(2) {
}


#tickets .ticket-grid .row {
}

#tickets .ticket-grid .row.odd {
  text-align: left;
}

#tickets .ticket-grid .row:hover {
}

#tickets .ticket-grid .row.even>div:nth-child(1) {
  order: 1;
}

#tickets .ticket-grid .row.even>div:nth-child(2) {
  order: 0;
  text-align: right;
}

#tickets .ticket-grid .row.odd>div:nth-child(1) {
  text-align: right;
}


#tickets .alert-bar {
}


#tickets .alert-bar h3 {
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: proxima-nova, sans-serif;
  font-size: 3.925rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
  margin-bottom: 0px;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d90000;
}

#tickets .alert-bar h4 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.225rem;
    margin: 0px;
    margin-bottom: 30px;
}

#tickets .alert-bar .body {
  margin: auto;
  width: 100%;
  max-width: 100%;
  font-weight: 300;
  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
}

#tickets .alert-bar .body strong {
  font-weight: 600;
  display: block;
  margin-top: 15px;
  color: #e20917;
}




/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

  #tickets .body {
    padding-bottom: 40px;
  }

  .festival-plan-bar button img {
    max-width: 100%!important;
  }

  #tickets .alert-bar h3 {
    font-size: 2.825rem;
  }

  #tickets .ticket-grid img {
    margin-bottom: -50px;
  }

  #tickets .ticket-grid .row.even>div:nth-child(2),
  #tickets .ticket-grid .row.odd {
    text-align: center;
  }

  #tickets .ticket-grid .row.odd>div:nth-child(1) {

  }

  #tickets .ticket-grid .row.even>div:nth-child(1) {
    order: 0;
  }

  #tickets .ticket-grid .row.even>div:nth-child(2) {
    order: 1;
  }

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  #tickets .body,
  #tickets .alert-bar .body {
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
  }

}
