#infos {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;
}

#infos .body {
  padding-bottom: 100px;
  max-width: 800px;
  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}

#infos .nav-tabs {
  max-width: 800px;
  margin: auto;
  margin-bottom: 30px;
}

#infos .nav-tabs>button {
  margin: 10px;
  border: 2px #000 solid;
  border-radius: 0px;
  text-transform: uppercase;
  color: #000!important;
  font-weight: 600;
}

#infos .nav-tabs>button.active {
  background-color: #e81600;
  color: #fff!important;
  border-color: #e81600;
}

#infos .accordion-item {
  border-radius: 0px!important;
  border: none;
  margin-bottom: 10px;
}

#infos .accordion {
  text-align: left;
  margin-bottom: 100px;
}

#infos .accordion-header button.collapsed {
  background-color: #f1f1f1;
  color: #000;
  border: none!important
}

#infos .accordion-header button.collapsed img {
  background-color: transparent;
}

#infos .accordion-header button img {
  margin-right: 20px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  padding: 6px;
}


#infos .accordion-header button::after {
  fill: #fff;
}

#infos .accordion-header button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#infos .accordion-header button.collapsed:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}


#infos .accordion-header button {
  background-color: #d90000;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125rem;
  font-size: 1.125rem;
  outline: none!important;
  box-shadow: none;
  z-index: 0;
}

#infos .accordion-body .btn {
  border: none;
  border-radius: 0px;
  margin-top: 10px;
  font-weight: 600;
  background-color: #d90000;
  color: #fff;
}

#infos .accordion-body img {
  max-width: 100%;
}



/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  #infos .body {
    padding-bottom: 30px;
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
  }

}
